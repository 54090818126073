* {
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
@import 'assets/styles/mixins.scss';

$backgroundcolor: white;
$backgroundcolordark: white;

body {
  font-family: 'Rubik', sans-serif;
}

.container {
  background-color: $backgroundcolor;
  display: flex;
  min-height: 100vh;
}

.left {
  // overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  // animation-name: left;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-delay: 1s;
  z-index: 40;
  width: 440px;
  -webkit-box-shadow: 5px 0 7px -1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 5px 0 7px -1px rgba(0, 0, 0, 0.6);
  box-shadow: 5px 0 7px -1px rgba(0, 0, 0, 0.6);
  background: #fff; // change this to change the left panel bg color
}

// .theform{
//   overflow: hidden;
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: column;
//   justify-content: center;
//   animation-duration: 1s;
//   animation-fill-mode: both;
//   animation-delay: 1s;

// }

.right {
  flex: 1;
  background-color: black;
  transition: 1s;
  // background-image: url('../../../../public/resources/images/login-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;

  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 15%;
  }
}

.toplogo {
  background-color: $backgroundcolordark;
  position: sticky;
  align-items: center;
  padding: 5px;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 4px 7px 1px rgba(41, 41, 41, 0.67);
  -moz-box-shadow: 0 4px 7px 1px rgba(41, 41, 41, 0.67);
  box-shadow: 0 4px 7px 1px rgba(41, 41, 41, 0.67);
}

.mainlayout {
  // flex: 1;
  margin-top: 19vh;
  margin-bottom: 2vh;
  overflow: auto;
  padding: 0 40px;
  min-height: 80vh;
  // background-color: #161616;
}

.headerlayout {
  position: fixed;
  background-color: #fff; //change this to change bg color of header
  -webkit-box-shadow: 0 1px 7px -1px rgba(41, 41, 41, 0.67);
  -moz-box-shadow: 0 1px 7px -1px rgba(41, 41, 41, 0.67);
  box-shadow: 0 1px 7px -1px rgba(41, 41, 41, 0.67);
  z-index: 40;
  align-content: center;
  padding: 3vh 0 3vh 0;
  width: 440px;

  @media screen and (max-width: $sm-max-width) {
    width: 100%;
  }
}

.footer {
  background-color: $backgroundcolordark;
  position: fixed;
  padding: 5px;
  bottom: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 -1px 7px 1px rgba(41, 41, 41, 0.67);
  -moz-box-shadow: 0 -1px 7px 1px rgba(41, 41, 41, 0.67);
  box-shadow: 0 -1px 7px 1px rgba(41, 41, 41, 0.67);
  width: 440px;

  @media screen and (max-width: $sm-max-width) {
    width: 100%;
  }
}

.brandlogo {
  height: 13vh;
}

section {
  height: 100%; /* takes the visible area of the "main" div */
  overflow: auto; /* recommended */
}

.sponsorlogo {
  width: 90%;
}

.animation {
  animation-name: move;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-delay: 2s;
}

.a1 {
  animation-delay: 2s;
}

.a2 {
  animation-delay: 2.1s;
}

.a3 {
  animation-delay: 2.2s;
}

.a4 {
  animation-delay: 2.3s;
}

.a5 {
  animation-delay: 2.4s;
}

.a6 {
  animation-delay: 2.5s;
}

@keyframes move {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@keyframes left {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    padding: 0 0;
    width: 440px;
  }
}
